import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ArchiveIcon from '@mui/icons-material/Archive';
import Divider from '@mui/material/Divider';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

function Menu({ navigate, page }) {

  const go_to_products = () => {
    navigate("/");
  }

  const go_to_channel_sync = () => {
    navigate("/Channel_sync");
  }

  const go_to_order_picking = () => {
    navigate("/Order_picking");
  }

  const go_to_shipments = () => {
    navigate("/shipping_status?order=");
  }

  const go_to_analytics = () => {
    navigate("/analytics");
  }

  const go_to_settings = () => {
    navigate("/settings");
  }

  const { logout } = useAuth0();


  return (
    <div style={{height: '100%', minHeight: '100vh', width: '220px', zIndex: '120', borderRight: '1px solid #d0d7de', boxShadow:'0px 1px 3px 0px rgba(208,215,222,0.93)', backgroundColor: '#f9fbfe'}}>

    <div style={{position: 'fixed', height: '30px', width: '100%', zIndex: '100', borderBottom: '1px solid #d0d7de', boxShadow:'0px 1px 3px 0px rgba(208,215,222,0.93)', backgroundColor: '#fff', padding: '5px 10px 15px 18px'}}>
      <img
      //src="https://aasprsoductimages.s3.eu-central-1.amazonaws.com/Logo_All_American_Sports_Transparant.png"
      src="https://aasproductimages.s3.eu-central-1.amazonaws.com/PimlyLogo.png"
      alt="Logo All-American Sports"
      height="38"
      />
    </div>


      <Grid style={{height: '100%', paddingTop: '70px'}} container
        direction="column"
        justifyContent="space-between"
      >

      <Grid item>

        <Grid item xs={12}>
          <Button onClick={() => go_to_products()} variant={page === 'products' ? 'contained' : 'text'} color="secondary" startIcon={<SportsBaseballIcon />} style={{margin: '20px 20px 10px 20px'}}>
            Products
          </Button>
          </Grid>

        <Grid item xs={12}>
          <Button onClick={() => go_to_channel_sync()} variant={page === 'channel_sync' ? 'contained' : 'text'} color="secondary" startIcon={<StorefrontIcon />} style={{margin: '0px 20px 10px 20px'}}>
            Offers
          </Button>
          </Grid>

        <Grid item xs={12}>
          <Button onClick={() => go_to_order_picking()} variant={page === 'order_picking' ? 'contained' : 'text'} color="secondary" startIcon={<ArchiveIcon />} style={{margin: '0px 20px 10px 20px'}}>
            Order picking
          </Button>
          </Grid>

        <Grid item xs={12}>
          <Button onClick={() => go_to_shipments()} variant={page === 'shipments' ? 'contained' : 'text'} color="secondary" startIcon={<LocalShippingIcon />} style={{margin: '0px 20px 30px 20px'}}>
            Shipments
          </Button>
          </Grid>

          <Grid item xs={12}>
            <Divider light />
            </Grid>

        <Grid item xs={12}>
          <Button onClick={() => go_to_analytics()} variant={page === 'analytics' ? 'contained' : 'text'} color="secondary" startIcon={<AssessmentIcon />} style={{margin: '10px 20px 10px 20px'}}>
            Analytics
          </Button>
          </Grid>

          </Grid>

          <Grid item>

          <Grid item xs={12}>
            <Button onClick={() => go_to_settings()} variant={page === 'settings' ? 'contained' : 'text'} color="inherit" startIcon={<SettingsIcon />} style={{margin: '10px 20px 10px 20px'}}>
              Settings
            </Button>
            </Grid>

            <Grid item xs={12}>
              <Button onClick={() => logout({ returnTo: window.location.origin })} variant={'text'} color="inherit" startIcon={<LogoutIcon />} style={{margin: '0px 20px 40px 20px'}}>
                Log out
              </Button>
              </Grid>

            </Grid>

        </Grid>

    </div>
  );
}

export default Menu;
