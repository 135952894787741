import React, { useEffect, useState, useCallback, useRef } from 'react'
import Menu from './Menu.js';
import Homepage from './Homepage.js';
import { useNavigate } from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAuth0 } from "@auth0/auth0-react";
import PublishIcon from '@mui/icons-material/Publish';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';


const toArray = selected => Object.keys(selected).map(id => id * 1);

const emptyText = <b style={{
  padding: 8,
  border: '1px solid #92278e',
  color: '#92278e',
  borderRadius: 4
}}>No products found</b>


const App = () => {
  const [gridRef, setGridRef] = useState(null);

  const { isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  const handleOnClickVariants = (variant) => {
    const url = "/?ean=&brand=&size=&color=&stock=&variant=" + variant
    window.location.href = url
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      bulk_item: {
        width: '120px',
        margin: '0px 0px 10px 10px',
        textAlign: 'center',
        height: '58px',
        paddingTop: '5px',
        color: '#555e68',
        '&:hover': {
          cursor: 'pointer',
          color: '#9452c0',
          border: '1px solid #9452c0'
        },
      },
      bulk_item_inactive: {
        width: '120px',
        margin: '0px 0px 10px 10px',
        textAlign: 'center',
        height: '58px',
        paddingTop: '5px',
        color: '#97999c'
      },
      bulk_icon: {
        color: '#000'
      },
      bulk_icon_inactive: {
        color: '#97999c'
      }
    }),
  );


  //get the url parameters for initial filtering
  const queryParams = new URLSearchParams(window.location.search);
  const brand_filter = queryParams.get('brand') ?? '';
  const ean_filter = queryParams.get('ean') ?? '';
  const stock_filter = queryParams.get('stock') ?? '';

  const defaultFilterValue = []

  if(ean_filter) {
    defaultFilterValue.push({ name: 'ean', operator: 'eq', type: 'string', value: ean_filter })
  }

  if(brand_filter) {
    defaultFilterValue.push({ name: 'brand', operator: 'eq', type: 'string', value: brand_filter })
  }

  if(stock_filter === 'In_stock') {
    defaultFilterValue.push({ name: 'stock', operator: 'neq', type: 'number', value: 0 })
  }

  if(stock_filter === 'Out_of_stock') {
    defaultFilterValue.push({ name: 'stock', operator: 'eq', type: 'number', value: 0 })
  }

  const classes = useStyles();

  const VariantButton = styled(Button)(({ theme }) => ({
    color: '#000000',
    marginTop: '5px',
    backgroundColor: '#eee',
    fontSize: '13px',
    textTransform: 'none',
    borderRadius: '5px',
    justifyContent: 'flex-start',
    padding: '5px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      backgroundColor: '#ddd',
    },
  }));


  const ExportButton = styled(Button)(({ theme }) => ({
    color: '#9452c0',
    fontSize: '15px',
    textTransform: 'none',
    minWidth: '100px',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: '#faf1ff',
    },
  }));

  const defaultSortInfo = { name: 'sync_status_shopify', dir: 1 }

  const defaultColumns = [
    { name: 'variant',						      header: 'Id', editable: false, defaultWidth: 80, defaultVisible: false },
    { name: 'main_photo',						    header: 'Image', editable: false,	defaultWidth: 85, render: ({ value, data }) => {return (<div style={{width: 'auto', height: '60px'}}> <img alt="" src={value} style={{width: '100%', height: '100%', objectFit: 'contain'}} /></div>) }},
    { name: 'variant_title',            header: 'Main Title', editable: false, defaultWidth: 500 },
    { name: 'brand',                    header: 'Brand', editable: false, defaultWidth: 150 },
    { name: 'stock_text',               header: 'Products', editable: false, defaultWidth: 200, render: ({ value, data }) => {return value !== '' ? <VariantButton className={classes.button_name} onClick={() => {handleOnClickVariants(data.variant)}}>{value}</VariantButton> : ''}},
    { name: 'shopify_product_id',       header: 'AAS Website', editable: false, render: ({ value, data }) => {return value ? <div style={{ display: 'inline-block' }}><CheckIcon style={{color: '#00bfa3'}}  /><br />{data.price_website_AAS}</div> : '' } },
    { name: 'bol_offers',               header: 'Bol.com', editable: false, render: ({ value, data }) => {return <div style={{ display: 'inline-block' }}>{value === data.number_of_variants ? <CheckIcon style={{color: '#00bfa3'}}  /> : (value > 0 && <HorizontalRuleIcon style={{color: '#ed6c02'}}  />)} <br />{data.bol_price}</div> }  },
    { name: 'sync_status_shopify',      header: 'Status', editable: false, render: ({ value, data }) => {return value === 'Changes made' ? <div style={{ display: 'inline-block' }}><Chip label={value} color='warning' size="small" /></div> : '' } },
  //  { name: 'shopify_product_id',       header: 'AAS Website', editable: false },
];

  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  const [dataSource, setDataSource] = useState([]);
  const [columns] = useState(defaultColumns);


  const [selected, setSelected] = useState({});

  const [selectionActive, setSelectionActive] = useState(false);

  const onSelectionChange = useCallback(({ selected }) => {
      setSelected(selected)
      setSelectionActive(toArray(selected).length > 0)
  }, [])


    //Popover
  const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;
    //End Popover


  const loadData = () => {
    const newDataSource = () => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };
      return fetch('/api/get_variants?filterBy=' + JSON.stringify(filterValue), requestOptions)
        .then(response => {
          return response.json().then(data => {
            return data;
          })
        });
      }

    setDataSource(newDataSource)
  }



  const clear_filters = () => {
    setEan_select('')
    setBrand_select('')
    setStock_select('')

  }

  const search_click = () => {
    navigate("/Channel_sync?ean=" + ean_select + '&brand=' + brand_select + '&stock=' + stock_select);
    const newFilterValue = []

    if(ean_select) {
      newFilterValue.push({ name: 'ean', operator: 'eq', type: 'string', value: ean_select })
    }

    if(brand_select) {
      newFilterValue.push({ name: 'brand', operator: 'eq', type: 'string', value: brand_select })
    }

    if(stock_select === 'In_stock') {
      newFilterValue.push({ name: 'stock', operator: 'neq', type: 'number', value: 0 })
    }

    if(stock_select === 'Out_of_stock') {
      newFilterValue.push({ name: 'stock', operator: 'eq', type: 'number', value: 0 })
    }

    const requestOptions = {
      method: 'GET',
      headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
    };

    const newDataSource = () => {
      return fetch('/api/get_variants' + '?filterBy='+JSON.stringify(newFilterValue), requestOptions)
        .then(response => {
          return response.json().then(data => {
            return data;
          })
        });
      }
    setDataSource(newDataSource)
    console.log('Refreshed?')
  }

  useEffect(() => {
    loadData();
  }, [])

  const [ean_select, setEan_select] = React.useState(ean_filter);
  const [brand_select, setBrand_select] = React.useState(brand_filter);
  const [stock_select, setStock_select] = React.useState(stock_filter);

  const handleChange_eanfilter = (event: SelectChangeEvent) => {
    setEan_select(event.target.value);
  };
  const handleChange_brandfilter = (event: SelectChangeEvent) => {
    setBrand_select(event.target.value);
  };
  const handleChange_stockfilter = (event: SelectChangeEvent) => {
    setStock_select(event.target.value);
  };


  //-----------------------------------------------
  //Load pre filled drop down boxes
  const [all_brands, setAll_brands] = useState([]);

  const requestOptions = {
    method: 'GET',
    headers: { 'Authentication': 'Basic bGxveWQ6aG9ua2JhbA==' }
  };

  useEffect(() => {
    const getBrandList = async () => {
      const res = await fetch("/api/get_distinct_attribute_values?attribute=brand", requestOptions);
      //console.log(res);
      const response = await res.json();
      setAll_brands(response);
    };
    getBrandList();
  }, []);

  //-----------------------------------------------


  const handleClickWebsite = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authentication", "Basic bGxveWQ6aG9ua2JhbA==");

    const my_variants = Object.keys(selected)

    var variants_append = ''

    var i;
    for(i=0; i < my_variants.length; i++){
        variants_append = variants_append + my_variants[i] + ","
    }

    var raw = '{"variant":"' + variants_append.slice(0, -1) + '"}';

    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("/api/sync_product_to_shopify", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));


    handleClosePopover();

    handleClickMessage();

  };


  const gridStyle = { marginTop: 10, height: '80vh' }

  const [openMessage, setOpenMessage] = React.useState(false);

  const handleClickMessage = () => {
      setOpenMessage(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMessage(false);
  };


  if (isAuthenticated) {

  return (

    <div>

    <Snackbar
        open={openMessage}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
        message="Note archived"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
     >
    <Alert onClose={handleCloseMessage} severity="success" sx={{ width: '100%' }}>
          The publication of the products has started. They will be visible soon.
        </Alert>
    </Snackbar>

    <Grid container>

      <Grid item xs="auto">

        <Menu navigate={navigate} page={'channel_sync'}/>

      </Grid>

      <Grid item xs>

  <div>

    <div style={{marginTop: '55px', padding: '10px 20px 10px 20px'}}>

    <div style={{margin: '5px 30px 5px 0px', height: '60px'}}>

    <Grid container justifyContent="space-between">

    <Grid item>

          <Grid container spacing={1}>
            <Grid item>
              <TextField id="outlined-basic" label="EAN" variant="outlined" size="small" value={ean_select} onChange={handleChange_eanfilter} />
          </Grid>

          <Grid item>
          <FormControl sx={{  minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Brand</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={brand_select}
                  label="Brand"
                  onChange={handleChange_brandfilter}
                >
                  <MenuItem value="">All brands</MenuItem>
                  {all_brands.map((attribute) => (
                    <MenuItem key={attribute.brand} value={attribute.brand}>{attribute.brand}</MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>

          <Grid item>
          <FormControl sx={{  minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Stock</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={stock_select}
                  label="Color"
                  onChange={handleChange_stockfilter}
                >
                  <MenuItem value="">No filter</MenuItem>
                  <MenuItem value={"In_stock"}>In stock</MenuItem>
                  <MenuItem value={"Out_of_stock"}>Out of stock</MenuItem>
                </Select>
              </FormControl>
          </Grid>



          <Grid item>
            <Button onClick={() => search_click()} style={{marginLeft: 10}}variant="contained" >Search</Button>
          </Grid>

          <Grid item>
            <Button onClick={() => clear_filters()} style={{marginLeft: 10}}variant="outlined" >Clear all</Button>
          </Grid>

          </Grid>
      </Grid>

      <Grid item>

      <Grid container>

            <Grid item>
              <Card variant="outlined" className={selectionActive ? classes.bulk_item : classes.bulk_item_inactive} onClick={handleClickPopover}>
              <PublishIcon className={selectionActive ? classes.bulk_icon : classes.bulk_icon_inactive} />
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  Publish
                </Typography>
              </Card>
              <Popover
                  id={id}
                  open={selectionActive && openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                <div>
                    <ExportButton onClick={handleClickWebsite}>AAS Website (Shopify)</ExportButton><br/>
                    <ExportButton>Bol.com</ExportButton><br/>
                    <ExportButton>Decathlon</ExportButton><br/>
                    <ExportButton>Amazon</ExportButton>
                    </div>
                </Popover>

            </Grid>

        </Grid>

      </Grid>


      </Grid>

      </div>

      <Typography sx={{ fontSize: 14 }} color="text.secondary">
          <b>Bulk actions</b> ({toArray(selected).length + (toArray(selected).length !== 1 ? ' product groups selected' : ' product group selected')})
      </Typography>

      <ReactDataGrid
          handle={setGridRef}
          idProperty="variant"
          selected={selected}
          style={gridStyle}
          rowHeight={65}
          showZebraRows={false}
          editable={true}
          columns={columns}
          emptyText={emptyText}
          pagination="local"
          checkboxColumn
          onSelectionChange={onSelectionChange}
          dataSource={dataSource}
          defaultSortInfo={defaultSortInfo}
          enableKeyboardNavigation={false}
      />

    </div>

    </div>

    </Grid>

  </Grid>

    </div>
  )
}
else {
  return (
      <Homepage />
)
}
}

export default () => <App />
