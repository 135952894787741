import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Products from './components/Products';
import ShippingStatus from './components/Shipping_status';
import Product from './components/Product';
import ChannelSync from './components/Channel_sync';
import OrderPicking from './components/Order_picking';
import Analytics from './components/Analytics';
import Settings from './components/Settings';

class App extends Component {
  render() {
    return (
    <Router>
        <div>
            <Routes>
              <Route exact path='/' element={<Products/>} />
              <Route exact path='/Shipping_status' element={<ShippingStatus/>} />
              <Route exact path='/Product' element={<Product/>} />
              <Route exact path='/Channel_sync' element={<ChannelSync/>} />
              <Route exact path='/Order_picking' element={<OrderPicking/>} />
              <Route exact path='/analytics' element={<Analytics/>} />
              <Route exact path='/settings' element={<Settings/>} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
